<template>
  <div>
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="mianArea">
      <div class="select">
        <!-- <el-button @click="initFirstPage">首页</el-button>
        <el-button @click="initDataSearchPage">数据检索</el-button>
        <el-button @click="initProductPage">产品生成</el-button> -->
        <div class="selectItem" :class="pagesNum == '1'?'selectBorder':''" @click="initFirstPage">
          首页
        </div>
        <div class="selectItem" :class="pagesNum == '2'?'selectBorder':''" @click="initDataSearchPage">
          数据检索
        </div>
        <div class="selectItem" :class="pagesNum == '3'?'selectBorder':''" @click="initProductPage">
          产品生成
        </div>
      </div>
      <iframe :src="urlContext" width="100%" height="100%" style="min-height: 600px;border: none;" id="windowContext"></iframe>
    </div>
  </div>
</template>

<script>
import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";
import axios from 'axios'
import CryptoJS from "crypto-js";
import configUtils from '@/utils/config';
export default {
  components: {
    nfBreadcrumb,
  },
  data() {
    return {
      startTime: null,
      pageName: "气象共享(weatherShare.vue)",
      pagesNum: "1",
      breadList: [
        {
          name: "资源共享",
        },
        {
          name: "气象共享",
        },
      ],
      pageUrl:"",
      user:{
        name:'NFAdmin',
        pwd:'NFqaz!@#',
        key:'',
        token:""
      },
      urlContext:''
    };
  },
  mounted () {
    this.initFirstPage();
    // this.determineLog();
  },
  beforeCreate() {
    this.startTime = performance.now();
    let this_ = this;
    //1.页面停留时间
    window.addEventListener('beforeunload', function() {
      let endTime = performance.now();
      let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("stayTime", stayTime);

      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          stayTime: stayTime,
        }
      })
    });
    //3.页面加载时间
    window.addEventListener('load', function() {
      let endTime = performance.now();
      let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("loadTime", loadTime);
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          loadTime: loadTime,
        }
      })
    });
  },
  created() {
    //2.用户浏览量
    window.SZHN_FIRE_BEAR?.trackEvent({
      key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
      segmentation: {
        pageViews: 1,
      }
    })
    //4.网络请求状态
    this.trackNetworkRequest("https://nfggy.com");
  },
  methods: {
    trackNetworkRequest(url) {
      let requestStartTime = performance.now();
      let this_ = this;

      fetch(url)
          .then(response => {
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestSuccessTime", requestTime);
            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "success",
                requestResult: response.toString()
              }
            })
          })
          .catch(error=>{
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestErrorTime", requestTime);

            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "error",
                requestResult: error.toString()
              }
            })
          })
    },
    initFirstPage(){
        this.pageUrl = "https://nfweather.nfggy.com:10086/#/nf-dashboard";
        this.initPage();
        this.pagesNum = "1"
      },
      initDataSearchPage(){
        const token = window.localStorage.getItem(configUtils.baseToken)
        if(token){
          this.pageUrl = "https://nfweather.nfggy.com:10086/#/nf-searchdata";
          this.initPage();
          this.pagesNum = "2"
        }else{
          this.$router.push({
            path: '/admin',
            query: {
              redirect: '/weatherShare',
              index: 1,
            },
          })
        }
      },
      initProductPage(){
        const token = window.localStorage.getItem(configUtils.baseToken)
        if(token){
          this.pageUrl = "https://nfweather.nfggy.com:10086/#/nf-product/historydata";
          this.initPage();
          this.pagesNum = "3"
        }else{
          this.$router.push({
            path: '/admin',
            query: {
              redirect: '/weatherShare',
              index: 1,
            },
          })
        }
      },
      initPage() {
        let $this=this;
        //首先根据用户名获取加密密钥
        axios.get('https://nfweather.nfggy.com:10086/api/system/auth/authority/token/' + $this.user.name)
          .then(function (response) {
            console.log(response.data);
            $this.user.key = response.data.token;

            //再根据用户名+密码+密钥进行身份认证
            $this.authentication();
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          });

      },
      authentication(){
        let $this=this;
        //再根据用户名+密码+密钥进行身份认证
        function signData() {
          let hmacSha = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256($this.user.name, $this.user.pwd));
          return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(hmacSha, $this.user.key));
        }
        let params={
          "token": $this.user.key,
          "signData": signData()
        };
        axios.put('https://nfweather.nfggy.com:10086/api/system/auth/authority/verify',params)
          .then(function (response) {
            console.log(response.data);
            $this.user.token = response.data.token;
            $this.showPages();
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
          });

      },
      showPages() {
        this.urlContext = this.pageUrl + "?token=" + this.user.token;
      },
    }

};
</script>

<style lang="less" scoped>
.mianArea{
  width:95%;
  margin: 0 auto;
  height: 950px;
  margin-bottom: 20px;
}
.select{
  display: flex;
  height: 70px;
  width: 100%;
  padding-top: 15px;
}
.selectItem{
  width: 120px;
  height: 60px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.selectBorder::before{
  content: '';
  width: 110px;
  height: 3px;
  position: absolute;
  bottom: 0;
  background-color: #265ae1;
}

</style>
